import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../api";
import { CopyBookingsRequest, Court } from "../../api/types";
import useShowToast from "../../hooks/useShowToast";
import useFetchCourts from "./hooks/useFetchCourts";
import useFetchWeeks from "./hooks/useFetchWeeks";
import { Week } from "./types";

type CopyWeekModalProps = {
  isOpen: boolean;
  onClose: () => void;
  court: Court;
  week: Week;
};

const CopyWeekModal = ({ isOpen, onClose, court, week }: CopyWeekModalProps) => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useShowToast();
  const { courts } = useFetchCourts();
  const { weeks, currentWeek } = useFetchWeeks();

  const [selectedCourt, setSelectedCourt] = useState<Court | null>(null);
  const [selectedWeek, setSelectedWeek] = useState<Week | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const showSuccessToast = () => {
    successToast({
      title: t("booking.modal_copy_week.copy.success_title"),
      description: t("booking.modal_copy_week.copy.success_message"),
    });
  };

  const showErrorToast = () => {
    errorToast({
      title: t("booking.modal_copy_week.copy.error_title"),
      description: t("booking.modal_copy_week.copy.error_message.default"),
    });
  };

  const handleCourtChange = (event: any) => {
    const courtId = parseInt(event.target.value, 10);
    const court = courts.find(court => court.id === courtId);
    if (!court) {
      return;
    }

    setSelectedCourt(court);
  };

  const handleWeekChange = (event: any) => {
    const weekId = parseInt(event.target.value, 10);
    const week = weeks.find(week => week.id === weekId);
    if (!week) {
      return;
    }

    setSelectedWeek(week);
  };

  const copyWeek = async () => {
    setIsLoading(true);
    console.log([selectedCourt, selectedWeek]);
    if (!selectedCourt || !selectedWeek) {
      setIsLoading(false);
      return;
    }

    const request: CopyBookingsRequest = {
      source_date: week.start,
      source_court_id: court.id,
      target_date: selectedWeek.start,
      target_court_id: selectedCourt.id,
    };

    await api.copyWeek(request);
    if (api.error) {
      console.log("Error during copy week", api.error);
      setIsLoading(false);
      showErrorToast();
      return;
    }

    // Close modal
    setIsLoading(false);
    onClose();
    showSuccessToast();
  };

  useEffect(() => {
    if (courts.length > 0 && weeks.length > 0 && currentWeek) {
      setSelectedCourt(courts[0]);
      setSelectedWeek(currentWeek);
    }
  }, [courts, weeks, currentWeek]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="500px">
        {/* Modal title */}
        <ModalHeader>{t("booking.modal_copy_week.copy_week")}</ModalHeader>

        <Divider />

        {/* Modal Body */}
        <ModalBody>
          {t("booking.modal_copy_week.body_message")}
          <VStack spacing={2} mt={4}>
            {/* Court */}
            <FormControl>
              <FormLabel>{t("booking.filters.court")}</FormLabel>
              <Select
                value={selectedCourt?.id}
                onChange={handleCourtChange}
                variant="outline"
                size="sm"
              >
                {courts.map(court => (
                  <option key={court.id} value={court.id}>
                    {court.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            {/* Week */}
            <FormControl>
              <FormLabel>{t("booking.filters.week")}</FormLabel>
              <Select
                value={selectedWeek?.id}
                onChange={handleWeekChange}
                variant="outline"
                size="sm"
              >
                {currentWeek &&
                  weeks
                    .filter(week => week.start >= currentWeek?.start)
                    .map(week => (
                      <option key={week.id} value={week.id}>
                        {format(week.start, "dd/MM/yyyy")}
                      </option>
                    ))}
              </Select>
            </FormControl>
          </VStack>
        </ModalBody>

        <Divider />

        {/* Modal Footer with buttons */}
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            {t("booking.modal_copy_week.btn_cancel")}
          </Button>
          <Button colorScheme="blue" ml={3} onClick={copyWeek} isLoading={isLoading}>
            {t("booking.modal_copy_week.copy.btn_title")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CopyWeekModal;
